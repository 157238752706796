var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("heads"),
      _c("div", { staticClass: "top" }, [
        _c("div", { staticClass: "top-main" }, [
          _c(
            "div",
            {
              staticStyle: {
                width: "392px",
                height: "573",
                "padding-top": "142px",
                "margin-left": "40px",
                float: "left"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "159px",
                    "font-size": "32px",
                    color: "#000"
                  }
                },
                [_vm._v(_vm._s(_vm.downloadWindows.title))]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "6px",
                    "font-size": "20px",
                    color: "#000"
                  }
                },
                [_vm._v("版本：V" + _vm._s(_vm.versionPC))]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "16px",
                    "font-size": "16px",
                    color: "#9B9B9B"
                  }
                },
                [
                  _c("div", [_vm._v(_vm._s(_vm.downloadWindows.descr))]),
                  _c("div", [_vm._v("更新时间：" + _vm._s(_vm.updateTimePC))]),
                  _c("div", [_vm._v("大小：" + _vm._s(_vm.sizePC) + "MB")])
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "download",
                  attrs: {
                    href: _vm.downUrlPC,
                    download: _vm.downloadWindows.name
                  },
                  on: { click: _vm.statistics }
                },
                [
                  _c("img", {
                    staticStyle: { width: "14px", height: "14px" },
                    attrs: { src: _vm.shape }
                  }),
                  _c(
                    "span",
                    { staticStyle: { "margin-left": "8px", color: "#fff" } },
                    [_vm._v("点击下载")]
                  )
                ]
              )
            ]
          ),
          _c("div", { staticClass: "backgroundImg1" }, [
            _c("img", {
              staticStyle: { "margin-top": "58px", "margin-left": "28px" },
              attrs: { src: _vm.kehuduan }
            })
          ]),
          _c(
            "div",
            {
              staticStyle: {
                width: "1200px",
                height: "160px",
                "margin-top": "70px",
                float: "left",
                display: "none"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "down1",
                  on: { mouseover: _vm.anzhuoover, mouseleave: _vm.anzhuoleave }
                },
                [
                  _c("div", { staticClass: "downImg1" }, [
                    _c("img", {
                      staticClass: "phoneImg",
                      attrs: { src: _vm.anzhuo }
                    }),
                    _c("img", {
                      staticClass: "erweima1",
                      attrs: { src: _vm.erweima }
                    })
                  ]),
                  _c("div", { staticClass: "downImgErweima1" }, [
                    _c("img", {
                      staticClass: "phoneImg3",
                      attrs: { src: _vm.erweima }
                    })
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-left": "18px",
                        float: "left",
                        "margin-top": "37px"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "186px",
                            height: "22px",
                            "font-size": "16px"
                          }
                        },
                        [_vm._v(_vm._s(_vm.downloadAndroid.title))]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "4px",
                            "font-size": "14px",
                            color: "#9B9B9B"
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(_vm._s(_vm.downloadAndroid.descr))
                          ]),
                          _c("div", [
                            _vm._v(
                              "更新时间：" +
                                _vm._s(_vm.downloadAndroid.updateTime)
                            )
                          ]),
                          _c("div", [
                            _vm._v("大小：" + _vm._s(_vm.downloadAndroid.size))
                          ])
                        ]
                      )
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "#fff" },
                      attrs: {
                        href: _vm.downloadAndroid.url,
                        download: _vm.downloadAndroid.name
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "downClick",
                          attrs: { type: "primary" }
                        },
                        [_vm._v("下载")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "down2",
                  on: { mouseover: _vm.appleover, mouseleave: _vm.appleleave }
                },
                [
                  _c("div", { staticClass: "downImg2" }, [
                    _c("img", {
                      staticClass: "phoneImg",
                      attrs: { src: _vm.apple }
                    }),
                    _c("img", {
                      staticClass: "erweima1",
                      attrs: { src: _vm.erweima }
                    })
                  ]),
                  _c("div", { staticClass: "downImgErweima2" }, [
                    _c("img", {
                      staticClass: "phoneImg3",
                      attrs: { src: _vm.erweima }
                    })
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-left": "18px",
                        float: "left",
                        "margin-top": "37px"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "186px",
                            height: "22px",
                            "font-size": "16px"
                          }
                        },
                        [_vm._v(_vm._s(_vm.downloadApple.title))]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "4px",
                            "font-size": "14px",
                            color: "#9B9B9B"
                          }
                        },
                        [
                          _c("div", [_vm._v(_vm._s(_vm.downloadApple.descr))]),
                          _c("div", [
                            _vm._v(
                              "更新时间：" +
                                _vm._s(_vm.downloadApple.updateTime)
                            )
                          ]),
                          _c("div", [
                            _vm._v("大小：" + _vm._s(_vm.downloadApple.size))
                          ])
                        ]
                      )
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "#fff" },
                      attrs: {
                        href: _vm.downloadApple.url,
                        download: _vm.downloadApple.name
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "downClick",
                          attrs: { type: "primary" }
                        },
                        [_vm._v("下载")]
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "other-program" }, [_vm._v("其他程序")]),
      _c("div", { staticClass: "other-program-content" }, [
        _c(
          "div",
          {
            staticClass: "others",
            on: { mouseover: _vm.overothers, mouseleave: _vm.leaveothers }
          },
          [
            _c("img", { staticClass: "phoneImg3", attrs: { src: _vm.sao1 } }),
            _c("div", { staticClass: "others-version" }, [
              _c("div", { staticClass: "others-name" }, [
                _vm._v(_vm._s(_vm.downloadOther1.title))
              ]),
              _c("div", { staticClass: "others-info" }, [
                _c("div", [_vm._v(_vm._s(_vm.downloadOther1.descr))]),
                _c("div", [_vm._v("大小：" + _vm._s(_vm.downloadOther1.size))])
              ])
            ]),
            _c(
              "a",
              {
                staticStyle: { color: "#fff" },
                attrs: {
                  href: _vm.downloadOther1.url,
                  download: _vm.downloadOther1.name
                }
              },
              [
                _c(
                  "el-button",
                  { staticClass: "downClick", attrs: { type: "primary" } },
                  [_vm._v("下载")]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "others2",
            on: { mouseover: _vm.overothers2, mouseleave: _vm.leaveothers2 }
          },
          [
            _c("img", { staticClass: "phoneImg3", attrs: { src: _vm.sao4 } }),
            _c("div", { staticClass: "others-version" }, [
              _c("div", { staticClass: "others-name" }, [
                _vm._v(_vm._s(_vm.downloadOther4.title))
              ]),
              _c("div", { staticClass: "others-info" }, [
                _c("div", [_vm._v(_vm._s(_vm.downloadOther4.descr))]),
                _c("div", [_vm._v("大小：" + _vm._s(_vm.downloadOther4.size))])
              ])
            ]),
            _c(
              "a",
              {
                staticStyle: { color: "#fff" },
                attrs: {
                  href: _vm.downloadOther4.url,
                  download: _vm.downloadOther4.name
                }
              },
              [
                _c(
                  "el-button",
                  { staticClass: "downClick", attrs: { type: "primary" } },
                  [_vm._v("下载")]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "others3",
            on: { mouseover: _vm.overothers3, mouseleave: _vm.leaveothers3 }
          },
          [
            _c("img", { staticClass: "phoneImg3", attrs: { src: _vm.sao3 } }),
            _c("div", { staticClass: "others-version" }, [
              _c("div", { staticClass: "others-name" }, [
                _vm._v(_vm._s(_vm.downloadOther3.title))
              ]),
              _c("div", { staticClass: "others-info" }, [
                _c("div", [_vm._v(_vm._s(_vm.downloadOther3.descr))])
              ])
            ]),
            _c(
              "a",
              {
                staticStyle: { color: "#fff" },
                attrs: { href: _vm.downloadOther3.url, target: "blank" }
              },
              [
                _c(
                  "el-button",
                  { staticClass: "downClick", attrs: { type: "primary" } },
                  [_vm._v("下载")]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "others4",
            on: { mouseover: _vm.overothers4, mouseleave: _vm.leaveothers4 }
          },
          [
            _c("img", { staticClass: "phoneImg3", attrs: { src: _vm.sao5 } }),
            _c("div", { staticClass: "others-version" }, [
              _c("div", { staticClass: "others-name" }, [
                _vm._v(_vm._s(_vm.downloadOther5.title))
              ]),
              _c("div", { staticClass: "others-info" }, [
                _c("div", [_vm._v(_vm._s(_vm.downloadOther5.descr))]),
                _c("div", [_vm._v("大小：" + _vm._s(_vm.downloadOther5.size))])
              ])
            ]),
            _c(
              "a",
              {
                staticStyle: { color: "#fff" },
                attrs: {
                  href: _vm.downloadOther5.url,
                  download: _vm.downloadOther5.name
                }
              },
              [
                _c(
                  "el-button",
                  { staticClass: "downClick", attrs: { type: "primary" } },
                  [_vm._v("下载")]
                )
              ],
              1
            )
          ]
        )
      ]),
      _c("foots")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }