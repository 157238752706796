<template>
  <div>
    <heads />
    <div class="top">
      <div class="top-main">
        <div style="width: 392px;height: 573;padding-top: 142px;margin-left:40px;float:left;">
          <div style="margin-top: 159px;font-size:32px;color:#000;">{{downloadWindows.title}}</div>
          <div style="margin-top: 6px;font-size: 20px;color:#000;">版本：V{{versionPC}}</div>
          <div style="margin-top: 16px;font-size: 16px; color: #9B9B9B;">
            <div>{{downloadWindows.descr}}</div>
            <div>更新时间：{{updateTimePC}}</div>
            <div>大小：{{sizePC}}MB</div>
          </div>
          <a :href="downUrlPC"
            :download="downloadWindows.name"
            class="download"
            @click="statistics">
            <img :src="shape"
              style="width:14px; height: 14px;">
            <span style="margin-left:8px;color:#fff;">点击下载</span>
          </a>
        </div>
        <div class="backgroundImg1">
          <img :src="kehuduan"
            style="margin-top: 58px;margin-left: 28px;">
        </div>
        <!-- display: none;暂时隐藏掉APP下载 -->
        <div style="width: 1200px;height: 160px;margin-top: 70px;float:left; display: none;">
          <div class="down1"
            @mouseover="anzhuoover"
            @mouseleave="anzhuoleave">
            <div class="downImg1">
              <img :src="anzhuo"
                class="phoneImg">
              <img :src="erweima"
                class="erweima1">
            </div>
            <div class="downImgErweima1">
              <img :src="erweima"
                class="phoneImg3">
            </div>
            <div style="margin-left: 18px;float:left;margin-top:37px;">
              <div style="width:186px;height:22px;font-size:16px;">{{downloadAndroid.title}}</div>
              <div style="margin-top: 4px;font-size: 14px; color: #9B9B9B;">
                <div>{{downloadAndroid.descr}}</div>
                <div>更新时间：{{downloadAndroid.updateTime}}</div>
                <div>大小：{{downloadAndroid.size}}</div>
              </div>
            </div>
            <a :href="downloadAndroid.url"
              :download="downloadAndroid.name"
              style="color:#fff;">
              <el-button type="primary"
                class="downClick">下载</el-button>
            </a>
          </div>
          <div class="down2"
            @mouseover="appleover"
            @mouseleave="appleleave">
            <div class="downImg2">
              <img :src="apple"
                class="phoneImg">
              <img :src="erweima"
                class="erweima1">
            </div>
            <div class="downImgErweima2">
              <img :src="erweima"
                class="phoneImg3">
            </div>
            <div style="margin-left: 18px;float:left;margin-top:37px;">
              <div style="width:186px;height:22px;font-size:16px;">{{downloadApple.title}}</div>
              <div style="margin-top: 4px;font-size: 14px; color: #9B9B9B;">
                <div>{{downloadApple.descr}}</div>
                <div>更新时间：{{downloadApple.updateTime}}</div>
                <div>大小：{{downloadApple.size}}</div>
              </div>
            </div>
            <a :href="downloadApple.url"
              :download="downloadApple.name"
              style="color:#fff;">
              <el-button type="primary"
                class="downClick">下载</el-button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="other-program">其他程序</div>
    <div class="other-program-content">
      <div class="others"
        @mouseover="overothers"
        @mouseleave="leaveothers">
        <img :src="sao1"
          class="phoneImg3">
        <div class="others-version">
          <div class="others-name">{{downloadOther1.title}}</div>
          <div class="others-info">
            <div>{{downloadOther1.descr}}</div>
            <div>大小：{{downloadOther1.size}}</div>
          </div>
        </div>
        <a :href="downloadOther1.url"
          :download="downloadOther1.name"
          style="color:#fff;">
          <el-button type="primary"
            class="downClick">下载</el-button>
        </a>
      </div>
      <div class="others2"
        @mouseover="overothers2"
        @mouseleave="leaveothers2">
        <img :src="sao4"
          class="phoneImg3">
        <div class="others-version">
          <div class="others-name">{{downloadOther4.title}}</div>
          <div class="others-info">
            <div>{{downloadOther4.descr}}</div>
            <div>大小：{{downloadOther4.size}}</div>
          </div>
        </div>
        <a :href="downloadOther4.url"
          :download="downloadOther4.name"
          style="color:#fff;">
          <el-button type="primary"
            class="downClick">下载</el-button>
        </a>
      </div>
      <!-- chrome谷歌 -->
      <div class="others3"
        @mouseover="overothers3"
        @mouseleave="leaveothers3">
        <img :src="sao3"
          class="phoneImg3">
        <div class="others-version">
          <div class="others-name">{{downloadOther3.title}}</div>
          <div class="others-info">
            <div>{{downloadOther3.descr}}</div>
          </div>
        </div>
        <!-- :download="downloadOther3.name" -->
        <a :href="downloadOther3.url"
          target="blank"
          style="color:#fff;">
          <el-button type="primary"
            class="downClick">下载</el-button>
        </a>
      </div>
      <div class="others4"
        @mouseover="overothers4"
        @mouseleave="leaveothers4">
        <img :src="sao5"
          class="phoneImg3">
        <div class="others-version">
          <div class="others-name">{{downloadOther5.title}}</div>
          <div class="others-info">
            <div>{{downloadOther5.descr}}</div>
            <div>大小：{{downloadOther5.size}}</div>
          </div>
        </div>
        <a :href="downloadOther5.url"
          :download="downloadOther5.name"
          style="color:#fff;">
          <el-button type="primary"
            class="downClick">下载</el-button>
        </a>
      </div>
    </div>
    <foots />
  </div>
</template>
<script>
import { get } from 'util/request';
import _ from 'lodash';
import { dateFormat } from 'util/ui';
import { downPost } from './api';
import heads from './common/head';
import foots from './common/foot';
import shape from '../assets/login/shape.svg';
import kehuduan from '../assets/login/kehuduan.svg';
import anzhuo from '../assets/login/anzhuo.svg';
import apple from '../assets/login/apple.svg';
import erweima from '../assets/login/erweima.svg';
import sao1 from '../assets/login/sao1.png';
import sao4 from '../assets/login/sao4.png';
import sao3 from '../assets/login/sao3.png';
import sao5 from '../assets/login/sao5.png';

export default {
  components: {
    heads,
    foots,
  },
  data() {
    return {
      // windows版
      downloadWindows: window.config.downloadWindows,
      downUrlPC: '',
      versionPC: '',
      sizePC: '',
      updateTimePC: '',
      // Android版
      downloadAndroid: window.config.downloadAndroid,
      // apple版
      downloadApple: window.config.downloadApple,
      // 其它版本
      downloadOther1: window.config.downloadOther1,
      downloadOther2: window.config.downloadOther2,
      downloadOther3: window.config.downloadOther3,
      downloadOther4: window.config.downloadOther4,
      downloadOther5: window.config.downloadOther5,
      shape,
      kehuduan,
      anzhuo,
      apple,
      erweima,
      sao1,
      sao4,
      sao3,
      sao5,
    };
  },
  created() {
    const num = _.parseInt(Math.random(0, 1) * 1000);
    get(`${this.downloadWindows.urlinfo}?${num}`).then(({ data, err }) => {
      if (!err) {
        const { version, size, date } = data;
        this.versionPC = version;
        this.sizePC = (size / 1024 / 1024).toFixed(1);
        this.updateTimePC = dateFormat(date, 'YYYY-MM-DD');
        this.downUrlPC = `${this.downloadWindows.downUrl}${version}/${this.downloadWindows.name}`;
      }
    });
  },
  methods: {
    /* 苹果 */
    appleover() {
      this.title = document.querySelector('.downImg2');
      this.title.style.display = 'none';
      this.title1 = document.querySelector('.downImgErweima2');
      this.title1.style.display = 'block';
      const apple = document.querySelector('.down2');
      apple.style.border = '1px solid #1890FF';
    },
    appleleave() {
      this.title = document.querySelector('.downImg2');
      this.title.style.display = 'block';
      this.title1 = document.querySelector('.downImgErweima2');
      this.title1.style.display = 'none';
      const apple = document.querySelector('.down2');
      apple.style.border = '1px solid #E8E8E8';
    },
    /* 安卓 */
    anzhuoover() {
      this.title = document.querySelector('.downImg1');
      this.title.style.display = 'none';
      this.title1 = document.querySelector('.downImgErweima1');
      this.title1.style.display = 'block';
      const anzhuo = document.querySelector('.down1');
      anzhuo.style.border = '1px solid #1890FF';
    },
    anzhuoleave() {
      this.title = document.querySelector('.downImg1');
      this.title.style.display = 'block';
      this.title1 = document.querySelector('.downImgErweima1');
      this.title1.style.display = 'none';
      const anzhuo = document.querySelector('.down1');
      anzhuo.style.border = '1px solid #E8E8E8';
    },
    /* 其他程序的悬浮效果 */
    overothers() {
      const others = document.querySelector('.others');
      others.style.border = '1px solid #1890FF';
    },
    leaveothers() {
      const others = document.querySelector('.others');
      others.style.border = '1px solid #E8E8E8';
    },
    overothers2() {
      const others = document.querySelector('.others2');
      others.style.border = '1px solid #1890FF';
    },
    leaveothers2() {
      const others = document.querySelector('.others2');
      others.style.border = '1px solid #E8E8E8';
    },
    overothers3() {
      const others = document.querySelector('.others3');
      others.style.border = '1px solid #1890FF';
    },
    leaveothers3() {
      const others = document.querySelector('.others3');
      others.style.border = '1px solid #E8E8E8';
    },
    overothers4() {
      const others = document.querySelector('.others4');
      others.style.border = '1px solid #1890FF';
    },
    leaveothers4() {
      const others = document.querySelector('.others4');
      others.style.border = '1px solid #E8E8E8';
    },
    /* 统计下载次数 */
    statistics() {
      downPost({ type: 'szjcyyy' });
    },
  },
};
</script>
<style lang="less" scoped>
.top {
  width: 100%;
  // height: 1080px;
  // 暂时调整
  height: 850px;
  background-image: url(../assets/login/background.svg);
}
.top-main {
  width: 1200px;
  // height: 1080px;
  // 暂时调整
  height: 850px;
  margin: auto;
}
.download {
  display: inline-block;
  background: #1890ff;
  margin-top: 32px;
  width: 392px;
  text-align: center;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
}
.backgroundImg1 {
  width: 682px;
  height: 573px;
  margin-top: 142px;
  background-image: url(../assets/login/background1.svg);
  float: left;
  margin-left: 80px;
}
.down1 {
  width: 578px;
  height: 158px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #e8e8e8;
  float: left;
}
.phoneImg {
  margin-top: 20px;
  margin-left: 49px;
  float: left;
}
.erweima1 {
  margin-top: 20px;
  margin-left: 28px;
  float: left;
}
.downClick {
  width: 88px;
  height: 32px;
  margin-left: 46px;
  margin-top: 64px;
}
.down2 {
  width: 578px;
  height: 158px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #e8e8e8;
  float: left;
  margin-left: 40px;
}
.downImgErweima1 {
  display: none;
}
.downImgErweima2 {
  display: none;
}
.phoneImg3 {
  width: 110px;
  height: 110px;
  margin-left: 25px;
  margin-right: 41px;
  margin-top: 25px;
  float: left;
}
.other-program {
  width: 1200px;
  height: 45px;
  font-size: 32px;
  margin: auto;
  margin-top: 90px;
}
.other-program-content {
  width: 1200px;
  height: 360px;
  margin: auto;
  margin-top: 48px;
  margin-bottom: 120px;
}
.others {
  width: 578px;
  height: 158px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #e8e8e8;
  float: left;
}
.others2 {
  width: 578px;
  height: 158px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #e8e8e8;
  float: left;
  margin-left: 40px;
}
.others3 {
  width: 578px;
  height: 158px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #e8e8e8;
  float: left;
  // margin-left: 40px;
  margin-top: 40px;
}
.others4 {
  width: 578px;
  height: 158px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #e8e8e8;
  float: left;
  margin-left: 40px;
  margin-top: 40px;
}
.others:hover,
.others2:hover,
.others3:hover,
.others4:hover {
  cursor: pointer;
}
.others-version {
  margin-left: 61px;
  float: left;
  margin-top: 37px;
}
.others-name {
  width: 186px;
  height: 22px;
  font-size: 16px;
}
.others-info {
  margin-top: 20px;
  font-size: 14px;
  color: #9b9b9b;
}
</style>
